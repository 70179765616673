import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import Swal from "sweetalert2";

//Miscellaneous
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { showPromos } from "../../store/ducks/promos/actions";
import DomPurify from "../../components/Sanitize/DomPurify";
import { isDesktop } from 'react-device-detect';
import { Helmet, HelmetProvider} from "react-helmet-async";

//Components
import { Icon } from "@iconify/react";
import LoadingPage from "../../components/LoadingPage/LoadingPage";
import MobileSubHeader from "../../components/PageHeader/MobileSubHeader";
import ShareModal from "./ShareModal";
// import AngpaoRain from "../Events/AngpaoRain/AngpaoRain";
import RayaEvents from "../Events/RayaEvents/RayaEvents";
import { claimAngpau, mobileAppsClaim } from "../../store/ducks/angpau/actions";


class PromosDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      showPromosImage: '',
      showPromosTitle: '',
      showPromosContent: '',
      showPromosDetailsImage: '',
      showPromosBannerImage: '',
      showMobileBannerImage: '',
      showShareSelect: false,
      currentEvent : "",
      bonusStatus: false,
      angpaoRain: true,
      showPromosCategory: '',
      showAngpau: false,
      angpaoReceived : "yes",
      showEvents: false,
      action: "",
      homePageEvents: true
    };
  }

  componentDidMount() {
    if (this.props.match.params) {
      const showPromotionsData = {};
      showPromotionsData.promotion_id = this.props.match.params.slug;
      showPromotionsData.lang_id = localStorage.getItem('lang_id'); // to fix native mobile ios app issue
      // showPromotionsData.country_id = this.props.currentUser.country_id || "1";
      // showPromotionsData.lang_id = this.props.currentUser.language_id || "1";

      let promosData = JSON.parse(localStorage.getItem('promosData'));
      let promosList = (promosData && promosData.promotion) || null;
      if (promosList !== null) {
        let filtered = {};

        promosList.filter(function(item) {
            if (item.promotionId === Number(showPromotionsData.promotion_id)) {
              return filtered = item;
            } else {
              return null;
            }
        });

        if (typeof filtered.promotionId !== undefined) {
          this.setState({
            isLoading: false,
            showPromosImage: filtered.promotionImage || "",
            showPromosTitle: filtered.promotionName || "",
            showPromosContent: filtered.content || "",
            showPromosDetailsImage: filtered.promo_details_image || "",
            showPromosBannerImage: filtered.promo_banner_image || "",
            showMobileBannerImage: filtered.mobileImage || "",
            openModal: true,
          });
        }
      }
      else {
        this.props.showPromos(showPromotionsData);
      }

      if(localStorage.getItem('accessToken')){
        const showPromotionsData = {};
        showPromotionsData.promotion_id = this.props.match.params.slug;
        showPromotionsData.lang_id = localStorage.getItem('lang_id'); // to fix native mobile ios app issue

        this.props.mobileAppsClaim();
        
        if(process.env.REACT_APP_ENV === "staging"){
          this.props.claimAngpau();
        }
        
        this.props.showPromos(showPromotionsData);
      }
    } else {
      this.props.history.push('/promos');
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.show_promos_data !== this.props.show_promos_data) {
      if (nextProps.show_promos_data.responseCode === 200) {
        this.setState({
          isLoading: false,
          showPromosImage: nextProps.show_promos_data.data[0] ? nextProps.show_promos_data.data[0].image : "",
          showPromosTitle: nextProps.show_promos_data.data[0] ? nextProps.show_promos_data.data[0].title : "",
          showPromosContent: nextProps.show_promos_data.data[0] ? nextProps.show_promos_data.data[0].content : "",
          showPromosDetailsImage: nextProps.show_promos_data.data[0] ? nextProps.show_promos_data.data[0].promo_details_image : "",
          showPromosBannerImage: nextProps.show_promos_data.data[0] ? nextProps.show_promos_data.data[0].promo_banner_image : "",
          showMobileBannerImage: nextProps.show_promos_data.data[0] ? nextProps.show_promos_data.data[0].mobileImage : "",
          showPromosCategory: nextProps.show_promos_data.data[0] ? nextProps.show_promos_data.data[0].title : "",
          openModal: true,
        });
      } else {
        this.setState({
          isLoading: false,
          openModal: false,
        });

        this.props.history.push('/promos');
      }
    }

    if (nextProps.location !== undefined && nextProps.location.params !== undefined && nextProps.location.params !== this.props.location.params) {
      this.setState({
        isLoading: true
      }, () => {
        const showNewPromotionsData = {};
        showNewPromotionsData.promotion_id = nextProps.location.params.promo_id;
        showNewPromotionsData.lang_id = localStorage.getItem('lang_id'); // to fix native mobile ios app issue
        // showNewPromotionsData.country_id = this.props.currentUser.country_id || "1";
        // showNewPromotionsData.lang_id = this.props.currentUser.language_id || "1";

        this.props.showPromos(showNewPromotionsData);
      });
    }

    if (nextProps.claim_angpau_result !== this.props.claim_angpau_result) {
      if (nextProps.claim_angpau_result.responseCode === 200) {
          if(nextProps.claim_angpau_result.data.eligible === false){
              this.setState({
                showAngpau: false
              });
          }else{
            this.setState({
              showAngpau: true
            });
          }
      } else {
        return false;
      }
    }

    if (nextProps.mobile_apps_promo_claim !== this.props.mobile_apps_promo_claim) {
      const { t } = this.props;
      if (nextProps.mobile_apps_promo_claim.responseCode === 200) {
          if(nextProps.mobile_apps_promo_claim.data.eligible === false || nextProps.mobile_apps_promo_claim.data.depositStatus === false){
              this.setState({
                showEvents: false
              });
          }else if(this.state.action === ""){
            this.setState({
              showEvents: true
            });
          }else if(this.state.action === "click"){
              Swal.fire({
                iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/mdi:check.svg?color=%23ffffff">' : '',
                icon: "info",
                title: t("global.success"),
                text: t("vip.success-claim"),
                confirmButtonText: t("global.ok"),
                customClass: (!isDesktop) ? {
                container: 'swal-mobile-container sucess',
                icon: 'swal-success-icon'
                } : []
            });
            
            this.setState({
              showEvents: false
            });
          }
      } else {
        return false;
      }
    }

    return true
  }

  toggleShareSelect = () => {
    this.setState({
      showShareSelect: !this.state.showShareSelect
    })
  }
  
  openEventsBonus = () => {
    this.setState({
      bonusStatus: true,
      showAngpau : true,
      homePageEvents: true,
    });
  };

  toggleAngpaoRain = () => {
    this.setState({
      angpaoRain: false,
    });
  };

  toggleHomeEvents = () => {
    this.setState({
      homePageEvents: false,
    });
  };

  imageImport = (r) => {
    let images = {};
    r.keys().map((item) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  claimEvents = () => {
    const { t } = this.props;
      Swal.fire({
        iconHtml: !isDesktop ? '<img src="https://api.iconify.design/ic:outline-question-mark.svg?color=%23ffffff">' : "",
        title: t("global.confirmation"),
        text: t("global.would-you-like-to-proceed") + "?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: t("global.save"),
        cancelButtonText: t("global.cancel"),
        customClass: !isDesktop
            ? {
                  container: "swal-mobile-container confirmation",
                  icon: "swal-confirmation-icon",
              }
            : [],
    }).then((result) => {
        if (result.isConfirmed) {
            Swal.fire({
                title: "",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                didOpen: () => {
                    Swal.showLoading();
                },
            });

            this.setState({
              action: "click"
            });

            let data = {};
            data.action = "click";
            this.props.mobileAppsClaim(data);
          }
        })
  }

  render() {
    const { t } = this.props;

    const tnc = this.imageImport(
      require.context("../../assets/images/tnc", false, /\.(png|jpe?g|svg)$/)
    );

    const tncMobile = this.imageImport(
      require.context("../../assets/images/tnc/mobile", false, /\.(png|jpe?g|svg)$/)
    );

    let imageLanguage = '';
    switch (this.props.currentUser.language_code) {
        case "zh-CN":
        case "zh_cn":
            imageLanguage = "-cn"
            break;
        case "ms":
            imageLanguage = "-bm"
            break;
        case "indon":
          imageLanguage = "-id"
          break;
        default:
            imageLanguage = ""
            break;
    }
    return (
      <Auxiliary>
        <HelmetProvider>
            <Helmet>
              <title>{process.env.REACT_APP_WEBSITE_TITLE} | {this.state.showPromosTitle}</title>
              <meta property="og:image" content={this.state.showPromosBannerImage} />
              <meta property="og:title" content={`${process.env.REACT_APP_WEBSITE_TITLE} | ${this.state.showPromosTitle}`} />
              <meta name="twitter:title" content={`${process.env.REACT_APP_WEBSITE_TITLE} | ${this.state.showPromosTitle}`} />
          </Helmet>
        </HelmetProvider>
        {localStorage.getItem('accessToken') && this.state.showAngpau === true && this.state.bonusStatus === true &&
        // <AngpaoRain
        // angpaoStatus={this.state.angpaoStatus}
        // angpaoRain={this.state.angpaoRain}
        // openAngpao ={this.openAngpao}
        // toggleAngpaoRain= {this.toggleAngpaoRain}
        // />
        <RayaEvents
        {...this.state}
        {...this.props}
        bonusStatus={this.state.bonusStatus}
        homePageEvents={this.state.homePageEvents}
        openEventsBonus={this.openEventsBonus}
        toggleHomeEvents={this.toggleHomeEvents}
      />
        }

        {
          this.state.isLoading && <LoadingPage />
        }
        {
          isDesktop ? (
            <>
              <div className="banner-col">
                <div className="banner-item">
                  <img src={this.state.showPromosBannerImage} alt={this.state.showPromosTitle} />
                </div>
              </div>
              <div className="x-container mt-0 pt-2">
                <div className="promo-share">
                {this.state.showPromosCategory === "Events" && this.state.showEvents === true && localStorage.getItem('accessToken')
                ? <button className='btn btn-gradient-blue angpao-get' onClick={this.claimEvents}>{t("mobile-apps-promo.received")}</button>
                : this.state.showPromosCategory === "Events" && this.state.currentEvent === "raya" && localStorage.getItem('accessToken') && this.props.match.params.slug === "221" || this.props.match.params.slug === "222" || this.props.match.params.slug === "59"
                ?  <button className='btn btn-gradient-blue angpao-get' onClick={this.openEventsBonus}>{t("mobile-apps-promo.received")}</button>  
                : ""
                }
                 
                <button className='btn btn-gradient-blue' style={{ marginLeft: "1rem" }} data-bs-toggle="modal" data-bs-target="#shareModal">
                  <Icon icon="mdi:share-variant"/>{t("promos.share")}
                </button>
                </div>
                {this.state.showPromosDetailsImage !== null && (
                  <section className="promos-table-section">
                    <div className="promos-table-container card-shadow">
                      <h2 className={`${this.state.showPromosDetailsImage ? '' : 'mb-0'}`}>{this.state.showPromosTitle}</h2>
                      {this.state.showPromosDetailsImage && this.state.showPromosDetailsImage !== "null" && (
                        <DomPurify text={this.state.showPromosDetailsImage} />
                      )}
                      {/* <div className="promos-claim-container">
                        <img src={img["question.png"]} alt="question"/>
                        <p>How to claim： Select bonus upon Deposit</p>
                      </div> */}
                    </div>
                  </section>
                )}

                <section className="term-section mt-5">
                  <div className="term-container card-shadow">
                    <img src={tnc[`term-condition${imageLanguage}.svg`]} alt="Terms Condition" />
                    <div className="term-content">
                      <DomPurify text={this.state.showPromosContent} />
                    </div>
                    <img src={tnc["term-footer.png"]} alt="Terms Footer" />
                  </div>
                </section>
              </div>
            </>
          ) : (
            <>
              <div className="promos-detail-m-header">
              {!localStorage.getItem("browseByApp") &&  
                  <MobileSubHeader
                    className="rm-absolute"
                    returnPage="/promos"
                    header=' '
                    style={{ justifyContent: "unset" }}
                  />
              }
                <img src={this.state.showMobileBannerImage} alt={this.state.showPromosTitle} className="w-100 h-auto" />
                <div className="promo-share">
                  {this.state.showPromosCategory === "Events" && this.state.showEvents === true && localStorage.getItem('accessToken')
                  ? <button className='btn btn-gradient-blue angpao-get' onClick={this.claimEvents}>{t("mobile-apps-promo.received")}</button> 
                  : this.state.showPromosCategory === "Events" && this.state.currentEvent === "raya" && localStorage.getItem('accessToken') && this.props.match.params.slug === "221" || this.props.match.params.slug === "222" || this.props.match.params.slug === "59"
                  ? <button className='btn btn-gradient-blue angpao-get' onClick={this.openEventsBonus}>{t("mobile-apps-promo.received")}</button>  
                  : ""
                  }
                  <button className='btn btn-gradient-blue' style={{ marginLeft: "1rem" }} onClick={this.toggleShareSelect}>
                    <Icon icon="mdi:share-variant"/>
                  </button>
                </div>
              </div>
              <div className="px-3 mb-5">
                <section className="promos-table-section">
                  <div className="promos-table-container card-shadow">
                    <h2>{this.state.showPromosTitle}</h2>
                    {this.state.showPromosDetailsImage !== null && this.state.showPromosDetailsImage !== "null" && (
                      // <span className="card-shadow">
                        <DomPurify text={this.state.showPromosDetailsImage} />
                      // </span>
                    )}
                    {/* <div className="promos-claim-container">
                      <img src={img["question.png"]} alt="question" />
                      <p>How to claim： Select bonus upon Deposit</p>
                    </div> */}
                  </div>
                </section>

                <section className="term-section mobile mt-3">
                  <div className="term-container card-shadow">
                    {(()=>{
                      if(localStorage.getItem('browseByApp')) {
                        let appLang = localStorage.getItem('lang');
                        if(appLang === 'zh-CN' || appLang === 'zh_cn'){
                          return <img src={tncMobile[`term-condition-cn.svg`]} alt="Terms Condition" />
                        }
                        else{
                          return <img src={tncMobile[`term-condition.svg`]} alt="Terms Condition" />
                        }                        
                      }
                      else{
                        return <img src={tncMobile[`term-condition${imageLanguage}.svg`]} alt="Terms Condition" />
                      }
                    })()}
                    {/* <img src={tncMobile[`term-condition${imageLanguage}.svg`]} alt="Terms Condition" /> */}
                    <div className="term-content">
                      <DomPurify text={this.state.showPromosContent} />
                    </div>
                    <img src={tncMobile["term-footer-m.jpg"]} alt="Terms Footer" />
                  </div>
                </section>
              </div>
            </>
          )
        }
        <ShareModal
          {...this.state}
          toggleShareSelect={this.toggleShareSelect}
        />
      </Auxiliary>
    );
  }
}

const mapStateToProps = (state) => {
  const { promos } = state;

  return {
    show_promos_data: promos.show_promos_data,
    currentUser: state.currentUser.data,
    claim_angpau_result: state.events.claim_angpau_result,
    mobile_apps_promo_claim: state.events.mobile_apps_promo_claim
  }
}

const mapDispatchToProps = {
  showPromos,
  claimAngpau,
  mobileAppsClaim
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(PromosDetail));
