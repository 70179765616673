import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";

//Miscellaneous
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import * as actions from "../../store/ducks/sport/actions";
import { getTopProfile, updateMemberPopUp } from "../../store/ducks/profile/actions";
import Swal from "sweetalert2";
import moment from 'moment-timezone';
import { isDesktop } from 'react-device-detect';
// import { LazyLoadImage } from 'react-lazy-load-image-component';

//Components
import GameContent from "../../components/GamePageContent/GamePageContent";
// import MobileSport from "./MobileSport";
import MobileHome from "../Home/MobileHome";
import LoadingPage from "../../components/LoadingPage/LoadingPage";
import { sportSeo } from "../../components/SeoMeta/SeoMeta";

export class Sport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 0,
      activeBtn: 0,
      triggerAnimation: true,
      loading: true,
      vendorList: [],
    };
  }

  componentDidMount() {
    // console.log("[componentDidMount]");
    let params = {};
    // params.country_id = this.props.currentUser.data.country_id;
    this.props.getVendorList(params);
  }

  shouldComponentUpdate(nextProps, nextState) {
    // console.log("[shouldComponentUpdate]", nextProps, nextProps.rs);
    if (nextProps.vendor !== undefined && nextProps.vendor !== this.props.vendor) {
      if (nextProps.vendor.responseCode === 200 && nextProps.vendor.msgType === "success") {
        let vendorList = nextProps.vendor.data.vendor_list;
        this.setState({
          loading: false,
          vendorList: vendorList
        });
      }
    }

    // still using?
    if (nextProps.rs !== undefined && nextProps.rs !== this.props.rs) {
      // console.log("running rs");
      if (nextProps.rs.responseCode === 200 && nextProps.rs.msgType === "success") {
        if (nextProps.sport.actionFrom === "PageContent") {
          Swal.close();
          if (nextProps.rs.data.hasOwnProperty('url')) {
            window.open(nextProps.rs.data.url);
          } else if (nextProps.rs.data.hasOwnProperty('gameError')) {
            this.gameErrorResponse(nextProps.rs.data.gameError);
          }
        }
      } else {
        const { t } = this.props;
        Swal.fire({
          iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/mdi:close.svg?color=%23ffffff">' : "",
          title: t("global.failed"),
          icon: "error",
          html: `${t("global.error")} (${nextProps.rs.message[0]}). ${t("global.contact-administrator")}`,
          showConfirmButton: true,
          confirmButtonText: t("global.ok"),
          customClass: (!isDesktop) ? {
            container: "swal-mobile-container failed",
            icon: "swal-failed-icon",
          } : [],
        });
      }
    }
    return true;
  }

  gameErrorResponse = (response) => {
    const { t } = this.props;

    switch (response.status) {
      case 1:
        Swal.fire({
          iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/mdi:exclamation.svg?color=%23ffffff">' : "",
          title: t("global.reminder"),
          icon: "error",
          html: `${t("global.error")} ${t("global.code")}: ${response.status} - ${t("global.kindly-refresh-the-page")}`,
          showConfirmButton: true,
          confirmButtonText: `${t("global.refresh")}`,
          customClass: (!isDesktop) ? {
            container: "swal-mobile-container reminder",
            icon: "swal-reminder-icon",
          } : [],
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
        break;
      case 2:
        const currentUserTimeZone = this.props.currentUser.data.time_zone;
        const startTime = moment(response.maintenance_start_time).tz(currentUserTimeZone === undefined ? "Asia/Kuala_Lumpur" : currentUserTimeZone).format('YYYY/MM/DD HH:mm:ss').toString();
        const endTime = moment(response.maintenance_end_time).tz(currentUserTimeZone === undefined ? "Asia/Kuala_Lumpur" : currentUserTimeZone).format('YYYY/MM/DD HH:mm:ss').toString();
        const timeZone = moment().tz(currentUserTimeZone === undefined ? "Asia/Kuala_Lumpur" : currentUserTimeZone).format('[(GMT] z[)]').toString();
        Swal.fire({
          iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/mdi:exclamation.svg?color=%23ffffff">' : "",
          title: t("global.reminder"),
          icon: "info",
          titleText: `${t("global.under-maintenance")}`,
          html: `${t("global.from")} <b>${startTime}</b> ${t("global.to")} <b>${endTime}</b> ${timeZone}`,
          showConfirmButton: true,
          confirmButtonText: t("global.ok"),
          customClass: (!isDesktop) ? {
            container: "swal-mobile-container reminder",
            icon: "swal-reminder-icon",
          } : [],
        });
        break;
      case 3:
        Swal.fire({
          iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/mdi:close.svg?color=%23ffffff">' : "",
          title: t("global.failed"),
          icon: "error",
          html: `${t("global.error")} ${t("global.code")}: ${response.status} - ${t("global.contact-administrator")}`,
          showConfirmButton: true,
          confirmButtonText: t("global.ok"),
          customClass: (!isDesktop) ? {
            container: "swal-mobile-container failed",
            icon: "swal-failed-icon",
          } : [],
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
        break;
      default:
        Swal.fire({
          iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/mdi:close.svg?color=%23ffffff">' : "",
          title: t("global.failed"),
          icon: "error",
          html: `${t("global.error")} ${t("global.code")}: 500 - ${t("global.contact-administrator")}`,
          showConfirmButton: true,
          confirmButtonText: t("global.ok"),
          customClass: (!isDesktop) ? {
            container: "swal-mobile-container failed",
            icon: "swal-failed-icon",
          } : [],
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
        break;
    }
  }

  accessGame = (game) => {
    if (!localStorage.getItem("accessToken")) {
      const { t } = this.props;
      Swal.fire({
        iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/fa6-solid:face-sad-tear.svg?color=%23b0b0b0">' : '',
        icon: "info",
        title: t("global.sorry"),
        text: t("global.login-proceed"),
        confirmButtonText: t("global.ok"),
        showCancelButton: (!isDesktop) ? false : '',
        customClass: (!isDesktop) ? {
          container: "swal-mobile-container sorry",
          icon: "swal-sorry-icon",
        } : []
      });
    } else {
      localStorage.setItem("gameLauncher", 'sport|' + game);
      window.open('/game-launcher');
    }
  };

  changeActivePage = (num) => {
    this.removeAnimation(num);
    setTimeout(() => {
      this.setState({ triggerAnimation: true, activePage: num, });
    }, 1000);
  };

  removeAnimation = (num) => {
    this.setState({
      triggerAnimation: false,
      activeBtn: num,
    });
  };

  imageImport = (r) => {
    let images = {};
    r.keys().map((item) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  render() {
    const { t } = this.props;
    const img = this.imageImport(
      require.context(
        "../../assets/images/sport",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );
    const icon = this.imageImport(
      require.context(
        "../../assets/images/icons",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );

    let icons = this.state.vendorList ? this.state.vendorList.map((val, idx) => {
      return (
        {
          activeImg: icon[`${val.game_vendor_slug}-icon-active.svg`],
          img: icon[`${val.game_vendor_slug}-icon.svg`],
          id: idx,
          slug: val.game_vendor_slug,
          hotStatus: val.hot_status
        }
      );
    }) : [];
    let sports = this.state.vendorList ? this.state.vendorList.map((val, idx) => {
      let content = "";
      let contentImg = "";
      let asset1 = "";
      let asset2 = "";
      switch (val.game_vendor_slug) {
        case "sb":
          content = `${t("sports.sb-content")}`;
          contentImg = "sport-ctg";
          asset1 = "cup";
          asset2 = "football";
          break;
        case "sbo":
          content = `${t("sports.sbo-content")}`;
          contentImg = "sport-ctg";
          asset1 = "cup";
          asset2 = "basketball";
          break;
        case "im":
          content = `${t("sports.im-content")}`;
          contentImg = "sport-ctg";
          asset1 = "basketball";
          asset2 = "cup";
          break;
        case "bti":
          content = `${t("sports.bti-content")}`;
          contentImg = "sport-ctg";
          asset1 = "cup";
          asset2 = "football";
          break;
        default:
          break;
      }
      let imageLanguage = '';
      switch (this.props.currentUser.data.language_code) {
        case "zh-CN":
          imageLanguage = "-cn"
          break;
        // case "ms":
        //   imageLanguage="-ms" 
        //   break;
        default:
          imageLanguage = "-en"
          break;
      }
      return (
        <>
          <div className="col-5">
            <GameContent
              pageTitle={img[`${val.game_vendor_slug}-title${imageLanguage}.webp`]}
              content={content}
              contentImg={img[`${contentImg}.webp`]}
              gameIcon={icons}
              link={"/"}
              fadeIn={this.state.triggerAnimation}
              activeBtn={this.state.activeBtn}
              changeActivePage={this.changeActivePage}
              accessGame={this.accessGame}
              nolink={true}
              curVendor={val.game_vendor_slug}
            />
          </div>
          <div className="col-7 position-relative">
            <div className={`sport-${val.game_vendor_slug === 'sbo' ? "st" : val.game_vendor_slug}-container ${this.state.triggerAnimation === true ? "fade-in-right" : "fade-out-right"}`}>
              <img className="asset-1" src={img[`${asset1}.webp`]} alt={`${asset1}-img`} />
              <img className="asset-2" src={img[`${asset2}.webp`]} alt={`${asset2}-img`} />
              <img key={img[`${val.game_vendor_slug}-img.webp`]} className="main-asset" src={img[`${val.game_vendor_slug}-img.webp`]} alt="gp-img" />
              {/* <LazyLoadImage key={img[`${val.game_vendor_slug}-img.png`]} className="main-asset" src={img[`${val.game_vendor_slug}-img.png`]} alt="gp-img" effect="blur"/> */}
            </div>
          </div>
        </>
      );
    }) : [];
    let sportsMobile = this.state.vendorList ? this.state.vendorList.map((val, idx) => {
      return {
        name: val.game_vendor_title,
        slug: val.game_vendor_slug,
        img: `${val.game_type}-${val.game_vendor_slug}-img.webp`,
        gameType: val.game_type,
        hotStatus: val.hot_status
      };
    }) : [];

    let topProfileData = null;
    let backupUrlLoading = true;
    let showBackUpUrl = false;

    if (this.props.topProfileData) {
      backupUrlLoading = false;
      topProfileData = this.props.topProfileData && this.props.topProfileData.data;
      showBackUpUrl = (topProfileData.topProfile.show_pop_up_url) === true ? true : false;
    }


    return (
      <Auxiliary>
        {sportSeo}

        {this.state.loading && <LoadingPage />}
        {
          isDesktop ? (
            <section
              className="provider-games-section"
              style={{ backgroundImage: `url(${img["sport-bg.webp"]}` }}
            >
              <div className="x-container-no-m">
                <div className="row">
                  {this.state.loading ? (
                    <></>
                  ) : (
                    <>
                      {sports[this.state.activePage]}
                    </>
                  )}
                </div>
              </div>
            </section>
          ) : (
            <MobileHome
              vendorList={sportsMobile}
              currentUser={this.props.currentUser.data}
              updateMemberPopUp={this.props.updateMemberPopUp}
              topProfileData={topProfileData}
              backupUrlLoading={backupUrlLoading}
              showBackUpUrl={showBackUpUrl}
              location={this.props.location}
            />
          )
        }
      </Auxiliary>
    );
  }
}

const mapStateToProps = state => {
  return {
    vendor: state.sport.vendor,
    sport: state.sport,
    rs: state.sport.rs,
    currentUser: state.currentUser,
    topProfileData: state.profile.top_profile_data
  }
};

const mapDispatchToProps = dispatch => {
  return {
    getVendorList: (params) => dispatch(actions.getVendorList(params)),
    // accessGame: (params, vendor) => dispatch(actions.accessGame(params, vendor)),
    getTopProfile,
    updateMemberPopUp: (params) => dispatch(updateMemberPopUp(params))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(Sport));
