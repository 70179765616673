import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { Link } from "react-router-dom";
import { isDesktop } from 'react-device-detect';
import { Helmet } from "react-helmet";
import Skeleton from "react-loading-skeleton";
import { CgDanger } from "react-icons/cg";

//Miscellaneous
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
// import moment from "moment-timezone";
import Swal from "sweetalert2";
import slugConf from "../../common/data/games.json";

//Store
import * as actions from "../../store/ducks/p2p/actions";
import * as gameFavAction from "../../store/ducks/gameFav/actions";

//Components
import Banner from "../../components/Banner/Banner";
import GamePagination from "../../components/Pagination/GamePagination";
import MobileProviderGames from "../MobileProviderGames/MobileProviderGames";
import { p2pSeoGp } from "../../components/SeoMeta/SeoMeta";

//Icons
import { Icon } from "@iconify/react";
export class P2P extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      vendorList: [],
      gameList: [],
      vendorSlug: "",
      vendorId: "",
      fav: "",
      pageLimit: 30,
      search: "",
      imageUrl: "",
      curPage: 1,
      totalPage: 0,
      hideDemo: true,
      memberFavGameList: []
    }
    this.gameRef = createRef();
  }

  componentDidMount() {
    let params = {};
    // params.country_id = this.props.currentUser.data.country_id;

    const path = window.location.pathname.split("/");
    if (path.length >= 3) {
      let curSlug = path[2];
      curSlug = ((slugConf['link-slug'] && slugConf['link-slug'][curSlug]) || '');
      this.setState({
        vendorSlug: curSlug
      });
      params.vendor_slug = curSlug;
      // console.log("[componentDidMount.slug]",curSlug);
    }

    this.props.getVendorList(params);
  }

  // getSnapshotBeforeUpdate(prevProps, prevState) {
  //   const path = window.location.pathname.split("/");
  //   if (path !== undefined && path.length >= 3 && path[2] !== undefined && path[2] !== "") {
  //     if(isDesktop){
  //       this.gameRef.current.scrollIntoView({ behavior: "auto", block: "start" });
  //     }
  //   }

  //   return null;
  // }

  componentDidUpdate(prevProps, prevState, snapshot) { }

  shouldComponentUpdate(nextProps, nextState) {
    // console.log("[shouldComponentUpdate]",nextProps);
    if(nextProps.location!==undefined && nextProps.location.state!==undefined && nextProps.location.state!==this.props.location.state){
      this.setState({
        vendorSlug: nextProps.location.state.vendorSlug,
        loading: true,
        fav: (nextProps.location.state.vendorSlug === 'pp') ? 1 : '', // pp set "hot" category as default, others vendor default as "all" category
        search: '',
      }, ()=>{
        let params = {};
        params.country_id = this.props.currentUser.data.country_id;
        params.language_code = this.props.currentUser.data.language_code;
        params.country_code = this.props.currentUser.data.country_code;
        params.vendor_slug = nextProps.location.state.vendorSlug;
        params.fav = this.state.fav;
        params.limit = this.state.pageLimit;
        params.search = this.state.search;
        if(localStorage.getItem("accessToken")){
          params.buffer = this.props.currentUser.data.id;
        }
        this.props.getGameList(params);

        if(localStorage.getItem("accessToken")){
          let data = {};
          data.buffer = this.props.currentUser.data.id;
          data.type = "p2";
          data.slug = nextProps.location.state.vendorSlug;
          this.props.getGameFavList(data);
        }
      });
    }

    if(nextProps.vendor!==undefined && nextProps.vendor!==this.props.vendor){
      if(nextProps.vendor.responseCode===200 && nextProps.vendor.msgType==="success"){
        let vendorList = nextProps.vendor.data.vendor_list;
        let vendorSlug = this.state.vendorSlug;
        if (vendorList.length > 0) {
          // default to first vendor if empty state
          vendorSlug = vendorSlug !== "" ? vendorSlug : vendorList[0].game_vendor_slug;
        }
        // console.log("[nextProps.vendor]",vendorSlug,vendorList);
        this.getVendorGames(vendorSlug, vendorList);
      }
    }

    if (nextProps.game !== undefined && nextProps.game !== this.props.game) {
      if (nextProps.game.responseCode === 200 && nextProps.game.msgType === "success") {
        this.setState({
          gameList: nextProps.game.data.game_list.row,
          imageUrl: nextProps.game.data.image_url,
          loading: false,
          totalPage: nextProps.game.data.game_list.pagination.total_page,
          curPage: nextProps.game.data.game_list.pagination.current_page
        });
      }
    }

    if(nextProps.game_fav!==undefined && nextProps.game_fav!==this.props.game_fav){
      if(nextProps.game_fav.responseCode===200 && nextProps.game_fav.msgType==="success"){
        this.setState({
          memberFavGameList: nextProps.game_fav.data.favorite_list
        });
      }
    }

    if(nextProps.update_game_fav!==undefined && nextProps.update_game_fav!==this.props.update_game_fav){
      if(nextProps.update_game_fav.responseCode===200 && nextProps.update_game_fav.msgType==="success"){
        if(localStorage.getItem("accessToken")){
          let data = {};
          data.buffer = this.props.currentUser.data.id;
          data.type = "p2";
          data.slug = this.state.vendorSlug;
          this.props.getGameFavList(data);

          if(this.state.fav===2){
            // refetch game list if updates occured at favorite tab
            this.setState({
              loading: true
            }, () => {
              let params = {};
              params.country_id = this.props.currentUser.data.country_id;
              params.language_code = this.props.currentUser.data.language_code;
              params.country_code = this.props.currentUser.data.country_code;
              params.vendor_slug = this.state.vendorSlug;
              params.fav = this.state.fav;
              params.limit = this.state.pageLimit;
              params.search = this.state.search;
              params.buffer = this.props.currentUser.data.id;
              
              this.props.getGameList(params);
            });
          }
        }
      }
    }

    return true;
  }

  getVendorGames = (vendorSlug, vendorList) => {
    this.setState({
      vendorSlug: vendorSlug,
      vendorList: vendorList,
      loading: true,
      fav: (vendorSlug === 'pp') ? 1 : '', // pp set "hot" category as default, others vendor default as "all" category
    }, () => {
      let params = {};
      params.country_id = this.props.currentUser.data.country_id;
      params.language_code = this.props.currentUser.data.language_code;
      params.country_code = this.props.currentUser.data.country_code;
      params.vendor_slug = vendorSlug;
      params.fav = (vendorSlug === 'pp') ? 1 : ''; // pp set "hot" category as default, others vendor default as "all" category
      params.limit = this.state.pageLimit;
      params.search = this.state.search;
      if(localStorage.getItem("accessToken")){
        params.buffer = this.props.currentUser.data.id;
      }
      this.props.getGameList(params);

      if(localStorage.getItem("accessToken")){
        let data = {};
        data.buffer = this.props.currentUser.data.id;
        data.type = "p2";
        data.slug = vendorSlug;
        this.props.getGameFavList(data);
      }
    })
  }

  getFavGames = (e, fav) => {
    e.preventDefault();
    this.setState({
      loading: true,
      fav: fav,
      gameList: []
    }, () => {
      let params = {};
      params.country_id = this.props.currentUser.data.country_id;
      params.language_code = this.props.currentUser.data.language_code;
      params.country_code = this.props.currentUser.data.country_code;
      params.vendor_slug = this.state.vendorSlug;
      params.fav = fav;
      params.limit = this.state.pageLimit;
      params.search = this.state.search;
      if(localStorage.getItem("accessToken")){
        params.buffer = this.props.currentUser.data.id;
      }
      this.props.getGameList(params);
    });
  }

  searchHandler = event => {
    this.setState({
      loading: true,
      search: event.target.value
    });
    let params = {};
    params.country_id = this.props.currentUser.data.country_id;
    params.language_code = this.props.currentUser.data.language_code;
    params.country_code = this.props.currentUser.data.country_code;
    params.vendor_slug = this.state.vendorSlug;
    params.fav = this.state.fav;
    params.limit = this.state.pageLimit;
    params.search = event.target.value.toLowerCase().replace(/\\/, "");
    if(localStorage.getItem("accessToken")){
      params.buffer = this.props.currentUser.data.id;
    }
    this.props.getGameList(params);
  }

  imageImport = (r) => {
    let images = {};
    r.keys().map((item) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  accessGame = (game) => {
    if (!localStorage.getItem("accessToken")) {
      const { t } = this.props;
        Swal.fire({
          iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/fa6-solid:face-sad-tear.svg?color=%23b0b0b0">' : '',
          icon: "info",
          title: t("global.sorry"),
          text: t("global.login-proceed"),
          confirmButtonText: t("global.ok"),
          customClass: (!isDesktop) ? {
            container: "swal-mobile-container sorry",
            icon: "swal-sorry-icon",
          } : []
        });
    } else {
      localStorage.setItem("gameLauncher", 'p2p|' + game + '|' + this.state.vendorSlug);
      window.open('/game-launcher');
    }
  };

  accessDemo = (game, demoUrl) => {
    if(!localStorage.getItem("accessToken")){
      const { t } = this.props;
        Swal.fire({
          iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/fa6-solid:face-sad-tear.svg?color=%23b0b0b0">' : '',
          icon: "info",
          title: t("global.sorry"),
          text: t("global.login-proceed"),
          confirmButtonText: t("global.ok"),
          customClass: (!isDesktop) ? {
            container: "swal-mobile-container sorry",
            icon: "swal-sorry-icon",
          } : []
        });
    }
    else{
      localStorage.setItem("gameLauncher", 'p2p|' + game + '|' + this.state.vendorSlug + '|' + demoUrl);
      window.open('/game-launcher');
    }
  };

  navigateList = (event, type, customPage = 0) => {
    event.preventDefault();
    let params = {};
    params.country_id = this.props.currentUser.data.country_id;
    params.language_code = this.props.currentUser.data.language_code;
    params.country_code = this.props.currentUser.data.country_code;
    params.vendor_slug = this.state.vendorSlug;
    params.fav = this.state.fav;
    params.limit = this.state.pageLimit;
    params.search = this.state.search;
    if(localStorage.getItem("accessToken")){
      params.buffer = this.props.currentUser.data.id;
    }
    switch (type) {
      case "page":
        params.page = customPage === 0 ? this.state.curPage : customPage;
        break;
      case "prev":
        params.page = this.state.curPage !== 1 ? this.state.curPage - 1 : this.state.curPage;
        break;
      case "next":
        params.page = this.state.curPage < this.state.totalPage ? this.state.curPage + 1 : this.state.curPage;
        break;
      default:
        params.page = this.state.curPage;
        break;
    }
    this.setState({ loading: true });
    this.props.getGameList(params);
  }

  updateMemberFavGame = (event, id) => {
    if(!localStorage.getItem("accessToken")){
      const { t } = this.props;
        Swal.fire({
          iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/fa6-solid:face-sad-tear.svg?color=%23b0b0b0">' : '',
          icon: "info",
          title: t("global.sorry"),
          text: t("global.login-proceed"),
          confirmButtonText: t("global.ok"),
          customClass: (!isDesktop) ? {
            container: "swal-mobile-container sorry",
            icon: "swal-sorry-icon",
          } : []
        });
    }
    else{
      let params = {};
      params.buffer = this.props.currentUser.data.id;
      params.alt_buffer = id;
      this.props.updateGameFav(params);
    }
  }

  render() {
    const { t } = this.props;
    const gameIcon = this.imageImport(
      require.context("../../assets/images/icons", false, /\.(png|jpe?g|svg)$/)
    );
    const favIcon = this.imageImport(
      require.context("../../assets/images/icons/mobile", false, /\.(png|jpe?g|svg)$/)
    );

    const tmpImg = this.imageImport(require.context("../../assets/images/placeholder/", false, /\.(png|jpe?g|svg)$/));
    const banner = this.imageImport(require.context("../../assets/images/banners", false, /\.(png|jpe?g|svg|webp)$/));
    let imageLanguage = "";
    switch (this.props.currentUser.data.language_code) {
        case "zh-CN":
            imageLanguage = "-cn";
            break;
        // case "ms":
        //     imageLanguage = "-ms"
        //     break;
        case "id":
            imageLanguage = "-id" 
            break;
        default:
            imageLanguage = "";
            break;
    }

    let seoMeta = null;
    let s3ImageUrl = process.env.REACT_APP_S3_IMG_URL;
    let imageUrl = this.state.imageUrl ? this.state.imageUrl : "";
    let gameCategory = this.state.vendorList ? this.state.vendorList : [];
    let curGameCategory = this.state.vendorList.filter((val) => val.game_vendor_slug === this.state.vendorSlug);
    const truncate = (value) =>
    value?.length > 17 ? `${value.substring(0, 17)}...` : value;
    let games = this.state.gameList ? this.state.gameList.map((game, idx) => {
      // let title = truncate(t(`games:${this.state.vendorSlug}.${game.game_code}`));
      let title = truncate(game.game_title);
      let img_url = "";
      switch(this.state.vendorSlug){
        case 'gp':
          seoMeta = <><Helmet><title>{process.env.REACT_APP_WEBSITE_TITLE} | {t(`routes:title.p2p-gp`)}</title></Helmet>{p2pSeoGp}</>
          img_url = `${s3ImageUrl}/${this.state.vendorSlug}/${game.game_code}.jpg`;
          break;
        case 'sg':
          img_url = `${imageUrl}${game.game_code}.jpg`;
          break;
        default:
          img_url = tmpImg['tmp_image.png'];
          break;
      }
      return {
        id: game.id,
        name: title,
        code: game.game_code,
        demo: game.support_demo,
        img_url: img_url,
        rtg_rate: game.rtg_rate,
        demo_url: game.demo_url
      };
    }) : [];
    return (
      <Auxiliary>
        {seoMeta !== null && seoMeta}
        {
          isDesktop ? (
            <>
              {/* <Banner category="p2p" bannerImg="p2p-banner.webp" /> */}
              {/* <Banner category="home" /> */}
              <section className="global-game-section">
              <div className="banner-col">
                            <div className="banner-item">
                                <img src={banner[`p2p-banners${imageLanguage}.webp`]} />
                            </div>
                        </div>
                <div className="x-container-no-m">
                  <div className="game-container p-4">
                    <div className="game-category">
                      <ul className="row p-0 g-3">
                        {gameCategory &&
                          gameCategory.map((item, index) => (
                            <li className="game-ctg-col" key={index}>
                              <Link className={`game-link ${item.hot_status === 1 ? 'hot' : ''} ${this.state.vendorSlug===item.game_vendor_slug ? "active" : ""}`} to={{pathname: `/p2p/${((slugConf['slug-link'] && slugConf['slug-link'][item.game_vendor_slug]) || '')}`, state: {vendorSlug: item.game_vendor_slug}}}>
                                <span>
                                  <img className="icon-grey" src={gameIcon[`${item.game_vendor_slug}-grey.svg`]} alt={`${item.game_vendor_slug}-gameIcon`} />
                                  <img className="icon-white" src={gameIcon[`${item.game_vendor_slug}-white.svg`]} alt={`${item.game_vendor_slug}-gameIcon`} />
                                </span>
                                <p>{t(`vendor.${item.game_vendor_slug}`)}</p>
                              </Link>
                            </li>
                          ))}
                      </ul>
                    </div>

                    <div className="game-header my-4">
                      <ul>
                        <p>{t("global.sort-by")}:</p>
                        <li onClick={(event)=>this.getFavGames(event,"")} className={this.state.fav==="" ? "active" : null}>
                          <Icon icon="mdi:view-grid" />
                          <span>{t("global.all")}</span>
                        </li>                        
                        <li onClick={(event)=>this.getFavGames(event,1)} className={this.state.fav===1 ? "active" : null}>
                          <Icon icon="ic:round-local-fire-department" />
                          <span>{t("global.hot")}</span>
                        </li>
                        <li onClick={(event) => this.getFavGames(event, 2)} className={this.state.fav === 2 ? "active" : null}>
                          <Icon icon="mdi:cards-heart" />
                          <span>{t("global.favorite")}</span>
                        </li>

                      </ul>
                      <div className="search-bar">
                        <input
                          className="form-control"
                          type="text"
                          placeholder={t("global.search")}
                          aria-label="Search"
                          onChange={(event)=>this.searchHandler(event)}
                          value={this.state.search}
                        />
                        <Icon icon="uil:search" />
                      </div>
                    </div>

                    <div className="game-list" ref={this.gameRef}>
                      <div className="row g-3">
                        {this.state.loading ? (
                          <>
                            {(()=>{
                              let display = [];
                              for(var i=0;i<10;i++){
                                display.push(
                                  <div className="col-4 col-5ths" key={i}>
                                    <div className="logo-cont">
                                      <Skeleton count={1} />
                                    </div>
                                    <div className="d-flex align-items-center p-2 title-cont">
                                      <Skeleton count={1} width={80} />
                                      <img className="ms-auto" src={gameIcon[`${this.state.vendorSlug}-grey.svg`]} alt={`${this.state.vendorSlug}-img`} />
                                    </div>
                                  </div>
                                )
                              }
                              return display;
                            })()}
                          </>
                        ) : (
                          <>
                          {this.state.fav===2 && !localStorage.getItem("accessToken") ?
                            <div className="col-12 text-center">{t("game-page.no-info")}</div>
                          :games &&
                          games.map((game, index) => {
                            return (
                            <div className="col-4 col-5ths" key={index}>
                              <div className="logo-cont">
                                <img
                                  className="w-100"
                                  src={game.img_url}
                                  alt={game.code}
                                  onError={(e)=>{
                                    e.target.onerror = null;
                                    e.target.src = this.src = tmpImg['tmp_image.png'];
                                  }}
                                />

                                {(()=>{
                                  if(this.state.memberFavGameList.length>0 && this.state.memberFavGameList.includes(game.id)){
                                    return (
                                      <img role="button" className="fav" src={favIcon["fav-red.png"]} alt="fav"
                                        onClick={(event) => this.updateMemberFavGame(event,game.id)}
                                      />
                                    );
                                  }
                                  else{
                                    return (
                                      <img role="button" className="fav" src={favIcon["fav-gray.png"]} alt="fav"
                                        onClick={(event) => this.updateMemberFavGame(event,game.id)}
                                      />
                                    );
                                  }
                                })()}

                                <div className="overlay">
                                  {(()=>{
                                    if(gameCategory!==undefined && gameCategory.length>0 && curGameCategory!==undefined && curGameCategory.length>0){
                                      if(curGameCategory[0].maintenance_status===0){
                                        return (
                                          <>
                                            <div onClick={()=>this.accessGame(game.code)} role="button" className="play-now">
                                              {t("global.play-now")}
                                            </div>

                                            {!this.state.hideDemo && game.demo===1 && (
                                              <div role="button" className="demo" onClick={() => this.accessDemo(game.code, game.demo_url)}>
                                                {t("global.demo")}
                                              </div>
                                            )}
                                          </>
                                        )
                                      }
                                      else{
                                        return (
                                          <>
                                            <div className="under-maintenance-text">
                                              <CgDanger />
                                              <p>
                                                {t("global.under-maintenance")}
                                                <br />
                                                {t("global.will-be-back-soon")}!
                                              </p>
                                            </div>
                                          </>
                                        )
                                      }
                                    }
                                  })()}
                                </div>
                              </div>
                              <div className="d-flex align-items-center p-2 title-cont">
                                <div>
                                  <h6 className="mb-0">{game.name}</h6>
                                  {/* <p className="rtp">{t("game-page.rtg")}:<span>{game.rtg_rate.toString().substr(0,2)}%</span></p> */}
                                </div>

                                <img
                                  className="ms-auto"
                                  src={gameIcon[`${this.state.vendorSlug}-grey.svg`]}
                                  alt={`${this.state.vendorSlug}-img`}
                                />
                              </div>
                            </div>
                            )
                          })}
                          </>
                        )}
                      </div>
                      <GamePagination 
                        totalPage={this.state.totalPage}
                        curPage={this.state.curPage}
                        data={games}
                        navigateList={(event, type, page)=> this.navigateList(event, type, page)}
                      />
                    </div>
                  </div>
                </div>
              </section>
            </>

          ) : (
            <MobileProviderGames
              returnPage={"/p2p"}
              title={t(`vendor.${this.state.vendorSlug}`)}
              gamesList={games}
              gameCategory={gameCategory}
              selectedVendor={curGameCategory}
              gameType="p2p"
              memberFavGamesList={this.state.memberFavGameList}
              searchHandler={this.searchHandler}
              accessGameFunc={this.accessGame}
              accessDemoFunc={this.accessDemo}
              navigateList={this.navigateList}
              updateMemberFavGame={this.updateMemberFavGame}
              changeTab={this.getFavGames}
              {...this.state}
              {...this.props}
            />
          )
        }
      </Auxiliary>
    );
  }
}

const mapStateToProps = state => {
  // console.log("[mapStateToProps]",state);
  return {
    vendor: state.p2p.vendor,
    game: state.p2p.game,
    rs: state.p2p.rs,
    currentUser: state.currentUser,
    game_fav: state.gameFav.game_fav,
    update_game_fav: state.gameFav.update_game_fav
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getVendorList: (params) => dispatch(actions.getVendorList(params)),
    getGameList: (params) => dispatch(actions.getGameList(params)),
    accessGame: (params, vendor) => dispatch(actions.accessGame(params, vendor)),
    getGameFavList: (params) => dispatch(gameFavAction.getGameFavList(params)),
    updateGameFav: (params) => dispatch(gameFavAction.updateGameFav(params))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(P2P));
